export const experienceData = [
  {
    id: 1,
    company: "Practice Group",
    jobtitle: "Fronted Developer",
    startYear: "Dec 2023",
    endYear: "Present",
  },
  // {
  //     id: 2,
  //     company: 'Fiverr(freelance)',
  //     jobtitle: 'Full-Stack Developer',
  //     startYear: 'Jun 2021',
  //     endYear: 'Jan 2022'
  // },
  {
    id: 2,
    company: "Learning New Technology For Everyday",
    jobtitle: "Self Learning",
    startYear: "2022",
    endYear: "Present",
  },
];
